//
// Page - Error 5
// Pages SASS files are compiled into separate css files
//

// Global config
@import "../../config";

// Layout config
@import "../../global/layout/config.scss";

.kt-error-v5 {
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;

  .kt-error_container {
    .kt-error_title {
      > h1 {
        font-size: 14rem;
        margin-left: 25rem;
        margin-top: 18rem;
        font-weight: 700;
        color: #314da7;
        -webkit-text-stroke-color: white;
      }
    }

    .kt-error_subtitle {
      margin-left: 26rem;
      margin-top: 3.57rem;
      font-size: 2.3rem;
      font-weight: 700;
      color: kt-base-color(label, 3);
    }

    .kt-error_description {
      margin-left: 26rem;
      font-size: 1.8rem;
      font-weight: 500;
      line-height: 130%;
      color: kt-base-color(label, 2);
    }
  }
}

@include kt-minimal-desktop() {
  .kt-error-v5 {
    background-position: bottom -80px left 1300px;

    .kt-error_container {
      .kt-error_title {
        > h1 {
          font-weight: 700;
          font-size: 12rem;
          margin-left: 7rem;
        }
      }

      .kt-error_subtitle {
        margin-left: 7rem;
        font-size: 2.3rem;
        font-weight: 700;
      }

      .kt-error_description {
        margin-left: 7rem;
        font-size: 1.8rem;
        font-weight: 500;
        line-height: 130%;
      }
    }
  }
}

@include kt-tablet {
  .kt-error-v5 {
    .kt-error_container {
      .kt-error_title {
        > h1 {
          font-weight: 700;
          font-size: 12rem;
          margin-left: 7rem;
        }
      }

      .kt-error_subtitle {
        margin-left: 7rem;
        font-size: 2.3rem;
        font-weight: 700;
      }

      .kt-error_description {
        margin-left: 7rem;
        font-size: 1.8rem;
        font-weight: 500;
        line-height: 130%;
      }
    }
  }
}

@include kt-mobile() {
  .kt-error-v5 {
    .kt-error_container {
      .kt-error_title {
        > h1 {
          font-size: 6rem;
          margin-top: 5rem;
          margin-left: 4rem;
        }
      }

      .kt-error_subtitle {
        margin-top: 2rem;
        margin-left: 4rem;
        font-size: 2rem;
        line-height: 2rem;
      }

      .kt-error_description {
        font-size: 1.4rem;
        margin-left: 4rem;
      }
    }
  }
}
