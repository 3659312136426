// 3rd-party Plugins
@import '~highlight.js/styles/googlecode.css';
@import '~perfect-scrollbar/css/perfect-scrollbar.css';

// Theme Styles
@import './_metronic/_assets/sass/style.react.scss';

// Default Layout Skins
@import './_metronic/_assets/sass/global/layout/header/skins/base/light.scss';
@import './_metronic/_assets/sass/global/layout/header/skins/menu/light.scss';
@import './_metronic/_assets/sass/global/layout/brand/skins/dark.scss';
@import './_metronic/_assets/sass/global/layout/aside/skins/dark.scss';

// Header Skins
.kt-header-base-light {
  @import './_metronic/_assets/sass/global/layout/header/skins/base/light.scss';
}

.kt-header-base-dark {
  @import './_metronic/_assets/sass/global/layout/header/skins/base/dark.scss';
}

// Header Menu Skins
.kt-header-menu-light {
  @import './_metronic/_assets/sass/global/layout/header/skins/menu/light.scss';
}
.kt-header-menu-dark {
  @import './_metronic/_assets/sass/global/layout/header/skins/menu/dark.scss';
}

// Brand Skins
.kt-brand-dark {
  @import './_metronic/_assets/sass/global/layout/brand/skins/dark.scss';
}

.kt-brand-light {
  @import './_metronic/_assets/sass/global/layout/brand/skins/light.scss';
}

// Aside skins
.kt-aside-dark {
  @import './_metronic/_assets/sass/global/layout/aside/skins/dark.scss';
}

.kt-aside-light {
  @import './_metronic/_assets/sass/global/layout/aside/skins/light.scss';
}

// Web Icon fonts
// @import "~socicon/css/socicon.css";
// @import "~@fortawesome/fontawesome-free/css/all.min.css";
// @import "./_metronic/_assets/plugins/line-awesome/css/line-awesome.css";
// @import "./_metronic/_assets/plugins/flaticon/flaticon.css";
// @import "./_metronic/_assets/plugins/flaticon2/flaticon.css";
.text-danger {
  color: #ffffff !important;
  text-align: center;
  padding: 10px;
  background: #bf3b3b;
  font-size: 14px;
  margin: 10px;
  border-radius: 2px;
}
.clear {
  margin-bottom: 10px;
  margin-top: 10px;
}
.input-group i {
  color: #fff;
}
.ql-container {
  height: 250px;
  background: #fff;
}

.select-button {
  background: #b92b27;
  color: white;
  box-shadow: 0px 4px 11px 1px #ccc;
}
.custom-error {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #fd397a;
}
h1 {
  font-size: 1.5rem;
}

.order-card {
  color: #fff;
}

.bg-c-blue {
  background: linear-gradient(45deg, #4099ff, #73b4ff);
}

.bg-c-green {
  background: linear-gradient(45deg, #b62ed8, #bf5cd8);
}

.bg-c-yellow {
  background: linear-gradient(45deg, #ffb64d, #ffcb80);
}

.bg-c-pink {
  background: linear-gradient(45deg, #ff5370, #ff869a);
}

.card {
  border-radius: 3px;
  -webkit-box-shadow: 0 1px 2.94px 0.06px rgba(4, 26, 55, 0.16);
  box-shadow: 0 1px 2.94px 0.06px rgba(4, 26, 55, 0.16);
  border: none;
  margin-bottom: 15px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.card .card-block {
  padding: 1px;
}

.order-card i {
  font-size: 25px;
}

.f-left {
  float: left;
}

.f-right {
  float: right;
}

.carda {
  border-radius: 3px;
  -webkit-box-shadow: 0 1px 2.94px 0.06px rgba(4, 26, 55, 0.16);
  box-shadow: 0 1px 2.94px 0.06px rgba(4, 26, 55, 0.16);
  border: none;
  margin-bottom: 15px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.carda .card-block {
  padding: 5px;
  margin: 5px;
}
