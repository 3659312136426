//
// Widget21
//

.kt-widget21 {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .kt-widget21__content {
    display: flex;
    padding-top: 1.15rem;
    padding-bottom: 1.25rem;
    flex-grow: 1;
    display: flex;

    .kt-widget21__item {
      display: flex;
      align-items: center;
      flex: 1;
      padding-left: 2rem;
      margin-top: 1.5rem;

      .kt-widget21__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;

        i {
          font-size: 1.4rem;
        }
      }

      .kt-widget21__info {
        padding-left: 1rem;
        flex: 1;

        .kt-widget21__title {
          display: block;
          font-size: 1.1rem;
          font-weight: 500;
          color: kt-base-color(label, 3);
        }

        .kt-widget21__sub {
          display: block;
          font-size: 1rem;
          color: kt-base-color(label, 2);
        }
      }
    }
  }

  .kt-widget21__chart {
    position: relative;

    canvas {
      border-bottom-left-radius: $kt-border-radius;
      border-bottom-right-radius: $kt-border-radius;
    }
  }
}

@include kt-mobile {
  .kt-widget21 {
    .kt-widget21__content {
      .kt-widget21__item {
        padding-left: 0;
        margin-top: 0.5rem;

        .kt-widget21__info {
          padding-left: 0.5rem;
        }
      }
    }
  }
}
